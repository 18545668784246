import {pt} from 'date-fns/locale';

import {ROUTES_PT} from '../routes/pt';
import {TIMEZONE_NAME} from '../timezone/spain';
import {GameId} from '../../app/games/data/game-id';
import {SearchLotteryType} from '../../app/games/game-metadata/data/search-lottery-type';

// TODO us environent data
export const ENVIRONMENT_PT = {
  id: 'pt',
  locale: {
    domain: '.pt',
    locale: 'pt-PT',
    dateLocale: pt,
    defaultCountry: 'Portugal',
    dateFormats: {
      format: 'DD/MM/YYYY',
      shortDayMonthYear: 'dd/MM/yy',
      dayMonthNameYear: 'd MMM, y',
      dayMonth: 'd MMM.',
      date: 'P',
      dateShort: 'dd MMM. - HH:mm',
      dayNameDate: 'eeee, P',
      dayNameMonthDate: 'eee, d MMM',
      dayMonthTime: 'd MMM., HH:mm',
      shortMonth: 'MMM',
      time: 'HH:mm',
      shortDateAndTime: 'dd/MM/yyyy HH:mm',
      dayName: 'eee',
    },
    zipCodeFormat: '0000-000',
    currencyCode: 'EUR',
    timezone: TIMEZONE_NAME,
    partnersLogo: 'assets/img/logos/partners-logos-pt.png',
    sponsorLogo: 'assets/img/menu/embajador-EURO.svg',
    user: {
      defaultCountry: 'pt',
      defaultPhoneCode: 'pt',
      allowDNI: false,
      allowDocumentNumber: true,
    },
    bankAccountType: 'IBAN',
    bankAccountFieldLengths: [4, 4, 4, 4, 4, 4, 1],
    menu: {
      downloadAndroidApp: true,
      downloadIosApp: true,
    },
    routes: ROUTES_PT,
  },
  app: {
    apkVerticalImage: true,
    ios: {
      id: '948041023',
      // link: 'https://itunes.apple.com/ES/app/id948041023?pid=pwa_menu',
      link: 'https://www.apple.com/pt/app-store/',
    },
    android: {
      link: 'https://tulotero.pt/descarga-android',
      downloadUsingPlayStore: false,
    },
  },
  fingerprint: {
    region: 'eu',
    endpoint: 'https://fp.web.tulotero.pt',
  },
  kyc: {
    allowSkip: false,
    methods: {
      defaultBasedOnIdentityDocument: true,
      voluntary: true,
    },
    enableNIF: true,
  },
  money: {fraudSystem: false, loadMin: 0.01, defaultPageDesktop: 'CARD'},
  menu: {
    background: 'assets/img/mobile/menu/background.jpg',
    backgroundChristmas: 'assets/img/mobile/menu/background-christmas.jpg',
    showClock: true,
  },
  profile: {
    background: 'assets/img/mobile/perfil/background.jpg',
    backgroundChristmas: 'assets/img/mobile/perfil/background-christmas.jpg',
    hiddenRequiredData: false,
  },
  init: {
    screenLogo: 'assets/img/mobile/screen-android-pt-pt.png',
  },
  balance: {
    showKycBanner: false,
  },
  groups: {
    enable: false,
    showInfoPrizes: false,
    shareUrl: 'https://tulotero.pt/g/',
  },
  games: {
    play: {
      savedPlayTimeout: 900,
    },
    results: {showCheckBanner: false},
    lottery: {
      allowCustomSearch: true,
      showManualLotteryResultInfo: true,
      fractionLabelType: 'fraction',
      showLotteryManualAmount: true,
      showBooths: true,
      allowSelectBoothId: [GameId.LOTARIA_POPULAR, GameId.LOTARIA_CLASSICA],
      searchLotteryType: SearchLotteryType.GENERIC,
    },
    logos: {
      rounded: true,
    },
  },
  preferences: {
    notificationCategory: 'notifications',
  },
  registration: {smsTimeout: {desktop: 60, mobile: 60}},
  geolocation: {
    highAccuracy: true,
    maxAccuracy: 5000000, // 5000km
  },
  social: {
    sponsorImg: 'assets/img/social/image-sponsor-pt.png',
  },
  features: {
    subscribe: {
      enable: true,
      maxDaysSelectorBreakpoint: 'sm',
    },
    profile: {
      showState: false,
    },
  },
  maintenance: {redirectUrl: 'maintenance-pt.html'},
};
