import * as deepmerge from 'deepmerge';

import {ENVIRONMENT_DEV} from './global/dev';
import {ENVIRONMENT_PT} from './local/pt';

export const environment = deepmerge.all<any>([
  {},
  ENVIRONMENT_DEV,
  ENVIRONMENT_PT,
  {
    angularProduction: true,
    enableAuthDomains: ['web.pt.stage.tulotero.net'],
    endpoint: 'https://web.pt.stage.tulotero.net/tuloteroweb/rest',
    endpointCommon: {
      ALL: 'https://static.pt.stage.tulotero.net/allinfo.json',
      pt_PT: 'https://static.pt.stage.tulotero.net/allinfo.json',
    },
    endpointTicketImage: 'https://web.pt.stage.tulotero.net',
    serviceWorkerEnabled: true,
    appleRedirectUri: 'https://web.pt.stage.tulotero.net',
    noDeleteAllInfoInVersionURL:
      'https://static.stage.tulotero.net/versions-web-pre.json',
  },
]);
